import React from "react";
import Link from "gatsby-link";
import "./style.scss";
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import useSound from 'use-sound';
import boopSfx from '../../sounds/pop-down.mp3';

const Navbar = () => {
  // const [isChecked, setIsChecked] = React.useState(
  //   false
  // );
  const [playActive] = useSound(boopSfx);
  const [playOn] = useSound(boopSfx);
  return (
    <div className="navbar-wrapper fixed-top">
    <nav className="navbar">
      <div className="top-menu">
        <div className="top-name">
          <Link to="/" className="name-link">
          Linas Vildžiūnas
          </Link>
         
        </div>
        <div className="navbar-nav-scroll">

          <ul className="navbar-nav bd-navbar-nav flex-row">
          <li
              className="nav-item"  
            >
              <Link to="/" className="nav-link" activeClassName="active">
                About
              </Link>
            </li>
            <li
              className="nav-item"
            >
              <Link to="/projects/" className="nav-link" activeClassName="active">
                Projects
              </Link>
            </li>
            {/* <li
              className={
                location.pathname === "/" ? "nav-item active" : "nav-item"
              }
            >
              <Link to="/" className="nav-link">
                About
              </Link>
            </li>
            
            <li
              className={
                location.pathname === "/projects/"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <Link to="/projects/" className="nav-link">
                Projects
              </Link>
            </li>
            <li
              className={
                location.pathname === "/now/"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <Link to="/now/" className="nav-link">
                Now
              </Link>
            </li> */}
            {/* <li
              className={
                location.pathname === "/blog/"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <Link to="/blog/" className="nav-link">
                Blog
              </Link>
            </li> */}
            <li className="nav-item color-toggle">
            <span><i className="las la-sun"></i></span>
            <ThemeToggler>
                  {({ theme, toggleTheme }) => (
                    <label className="switch">
                      <input
                        type="checkbox"
                        className="checkbox"
                        onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')}
                        checked={theme === 'dark'}
                        // onChange={() => setIsChecked(!isChecked)}
                        onMouseDown={playActive}
                        onMouseDown={playOn}
                        // onClick={playActive}
                      />
                      <span className="slider"></span>
                    </label>
                  )}
                </ThemeToggler>
                <span><i className="las la-moon"></i></span>
            </li>

          </ul>
        </div>
      </div>
      <div className="bottom-menu">
        <div className="left-menu">
          <a href="https://www.facebook.com/linas.vildziunas" target="_blank"><i className="lab la-facebook"></i></a>
          <a href="https://www.instagram.com/linasv/" target="_blank"><i className="lab la-instagram"></i></a>
          <a href="https://www.linkedin.com/in/linas-vildziunas-6818a8b7/" target="_blank"><i className="lab la-linkedin"></i></a>
          <a href="https://github.com/vlinas" target="_blank"><i className="lab la-github"></i></a>
        </div>
        <div className="right-menu">
         <p>Updated 2020/07/16</p>
        </div>
      </div>
    </nav>

    </div>
  );
};

export default Navbar;
